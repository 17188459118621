import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import TransparentBackground from '../TransparentBackground'

const Modal = ({ show, onClose, children, modalId }) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const handleCloseClick = (e) => {
    e.preventDefault()
    onClose()
  }

  const modalContent = (
    <div>
      <TransparentBackground onClick={handleCloseClick} show={show} />
      <StyledModal
        className={`absolute w-[21rem] border bg-gray-100 duration-300 ease-in-out dark:border-gray-700 dark:bg-gray-900 lg:w-1/3 xl:w-1/4 ${
          show ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
      >
        {children}
      </StyledModal>
    </div>
  )

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById(modalId))
  } else {
    return null
  }
}

const StyledModal = styled.div`
  height: 400px;
  padding: 15px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const StyledModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export default Modal
