import Modal from '@/components/modals/Modal'

export default function HowToPlayModal({ setHowToPlayModal, showHowToPlayModal }) {
  return (
    <Modal onClose={() => setHowToPlayModal(false)} show={showHowToPlayModal} modalId="modal-root">
      <div className="flex h-full flex-col justify-between">
        <div className="flex justify-center">
          <div className="m-3 flex-1 pl-7">
            <h2 className="text-center font-semibold text-gray-600 dark:text-gray-400">
              HOW TO PLAY ANIMERDLE
            </h2>
          </div>
          <div className="flex justify-self-end">
            <button
              aria-label="Close how to play modal"
              onClick={() => {
                setHowToPlayModal(false)
              }}
              className="text-custom-mg border-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="gray"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
        <div className="flex h-full w-full flex-col justify-between p-2 xl:p-4">
          <div className="flex gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="gray"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-14 w-14 xl:h-7 xl:w-7"
            >
              <circle cx="5.5" cy="17.5" r="2.5"></circle>
              <circle cx="17.5" cy="15.5" r="2.5"></circle>
              <path d="M8 17V5l12-2v12"></path>
            </svg>
            <span>Listen to the audio clip and try to guess which Anime it is from</span>
          </div>

          <div className="flex gap-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="gray"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-14 w-14 xl:h-8 xl:w-8"
            >
              <path d="M13 17l5-5-5-5M6 17l5-5-5-5" />
            </svg>

            <span>Skipping or incorrect attempts will unlock larger portions of the audio</span>
          </div>

          <div className="flex gap-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="gray"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-11 w-11 xl:h-6 xl:w-5"
            >
              <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
            </svg>
            <span>
              Answer with the fewest number of attempts possible and guess today's{' '}
              <strong>Animerdle</strong>!
            </span>
          </div>

          <button
            aria-label="Play the game"
            onClick={() => setHowToPlayModal(false)}
            className="w-25 mx-auto rounded bg-primary-500 py-2 px-4 py-1 font-semibold text-white  duration-200 ease-in-out hover:scale-105 hover:bg-primary-700"
          >
            I'm Ready!
          </button>
        </div>
      </div>
    </Modal>
  )
}
